/* The actual timeline (the vertical ruler) */
.timeline,
.pastTimeline {
  position: relative;
  /* max-width: 1200px; */
  margin-left: 15px;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 3px;
  background-color: #5dd9c4;
  top: 0;
  bottom: 0;
  left: 10%;
  margin-left: -10px;
}

.pastTimeline::after {
  content: '';
  position: absolute;
  width: 3px;
  background-color: #d9d9d9;
  top: 0;
  bottom: 0;
  left: 10%;
  margin-left: -10px;
}

/* Container around content */
.timeline-container,
.pastTimeline-container {
  padding: 10px 3px;
  position: relative;
  background-color: inherit;
  width: 90%;
}

/* The circles on the timeline */
.timeline-container::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  left: 50%;
  background-color: white;
  border: 4px solid #5dd9c4;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

.pastTimeline-container::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  left: 50%;
  background-color: white;
  border: 4px solid #d9d9d9;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

.pastConsultationContainer {
  margin-bottom: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.consultationContainer {
  margin-bottom: 0.5em;
  display: flex;
  flex-direction: column;
}

.consultaionDateTime {
  font-size: 0.9em;
  margin-right: 16px;
  align-content: center;
}

/* Place the container to the right */
.right {
  left: 10%;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -16px;
}

/* The actual content */
.content {
  padding: 4px;
  background-color: white;
  position: relative;
  border-radius: 6px;
  font-size: 12px;
}

.lastNoteCointainer {
  line-height: 16px;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}

.viewMoreButton {
  cursor: pointer;
  font-size: 12px;
  font-family: sans-serif;
  border: none;
  margin: 5px 0;
  background-color: white;
  color: #3d8d91;
  padding: 0;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after,
  .pastTimeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .timeline-container,
  .pastTimeline-container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timeline-container::before,
  .pastTimeline-container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 15px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }
}
