.moving-element {
  position: relative;
  animation: moveBackAndForth 1.2s ease infinite; /* Adjust the duration and animation name as needed */
}

@keyframes moveBackAndForth {
  0%,
  100% {
    left: -3px;
  }
  50% {
    left: 3px;
  }
}
