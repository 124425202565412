html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media only screen and (max-width: 768px) {
  .logo {
    margin-left: 15px;
  }
}

.logout {
  margin-right: 10px;
}

@media only screen and (max-width: 768px) {
  .logout {
    margin-right: 35px;
  }
}

@media only screen and (max-width: 600px) {
  .logout {
    margin-right: 15px;
  }
}

/* ant design */
.todoTable th.ant-table-cell {
  padding: 6px 16px !important;
}

.todoTable tr.ant-table-row {
  cursor: pointer !important;
}

.activityTable tr.ant-table-row {
  cursor: pointer !important;
}

.planDetailGoalTable tr.ant-table-row {
  cursor: pointer !important;
}

.ant-btn:focus {
  border-color: #d9d9d9 !important;
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.85) !important;
}
